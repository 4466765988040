import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fin: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    accuracy: {
      type: Number,
      required: true,
    },
  },
  computed: {
    /**
     * Relatively temporary adjustment to distinguish between
     * metrics which have different fins, but the same display.
     * @returns (str)
     */
    fullFieldName() {
      let prefix = '';
      if (this.fin.includes(':lp:')) {
        prefix = '(LP) ';
      } else if (this.fin.includes(':fund:')) {
        prefix = '(Fund) ';
      } else if (this.fin.includes(':investments:')) {
        prefix = '(Company) ';
      }
      return prefix + this.fieldName;
    },
  },
});
