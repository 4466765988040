<template>
  <div class="card card-verification">
    <h6 class="card-title">
      Metrics Verified
    </h6>
    <div class="card-content m-ver-xl">
      <div class="stat-stack-columns-2 stat-stack-columns-coloured">
        <div class="stat-stack">
          <div v-if="statusIsReady">
            {{ proportionVerified }}%
          </div>
          <freyda-loader
            v-else-if="statusIsLoading"
            :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
          />
          <div v-else>
            --
          </div>
          <div>Verified</div>
        </div>
        <div class="stat-stack">
          <div v-if="statusIsReady">
            {{ proportionUnverified }}%
          </div>
          <freyda-loader
            v-else-if="statusIsLoading"
            :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
          />
          <div v-else>
            --
          </div>
          <div>Unverified</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FreydaLoader from '@/components/general/FreydaLoader.vue';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: { FreydaLoader },
  props: {
    accuracyStatus: {
      type: String,
      required: true,
    },
    totalMetrics: {
      type: Number,
      required: true,
    },
    totalMetricsVerified: {
      type: Number,
      required: true,
    },
  },
  computed: {
    statusIsLoading() {
      return this.accuracyStatus === allowedStates.IS_LOADING;
    },
    statusIsReady() {
      return this.accuracyStatus === allowedStates.IS_READY;
    },
    proportionVerified() {
      if (!this.totalMetrics) {
        return 0;
      }
      return this.formatAccuracy(this.totalMetricsVerified / this.totalMetrics);
    },
    proportionUnverified() {
      if (!this.totalMetrics) {
        return 0;
      }
      return this.formatAccuracy((this.totalMetrics - this.totalMetricsVerified) / this.totalMetrics);
    },
  },
  methods: {
    formatAccuracy(accuracy) {
      if (accuracy === null) {
        return null;
      }
      return (accuracy * 100).toFixed(0.2);
    },
  },
};
</script>
