<template>
  <div class="card card-upload">
    <h6 class="card-title">
      Uploads
    </h6>
    <div class="card-content m-ver-xl">
      <div class="stat-stack-columns-2">
        <div class="stat-stack">
          <div v-if="statusIsReady">
            {{ uploadsLastMonth }}
          </div>
          <freyda-loader
            v-else-if="statusIsLoading"
            :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
          />
          <div v-else>
            --
          </div>
          <div>Last month</div>
        </div>
        <div class="stat-stack">
          <div v-if="statusIsReady">
            {{ uploadsTotal }}
          </div>
          <freyda-loader
            v-else-if="statusIsLoading"
            :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
          />
          <div v-else>
            --
          </div>
          <div>Total</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import { isBefore, parseISO } from 'date-fns';
import FreydaLoader from '@/components/general/FreydaLoader.vue';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: { FreydaLoader },
  data() {
    return {
      status: allowedStates.IS_BLANK,
      toast: useToast(),
      uploadsLastMonth: null,
      uploadsTotal: null,
    };
  },
  computed: {
    statusIsLoading() {
      return this.status === allowedStates.IS_LOADING;
    },
    statusIsReady() {
      return this.status === allowedStates.IS_READY;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getStatUpload: 'usage/getStatUpload',
    }),
    init() {
      this.status = allowedStates.IS_LOADING;
      this.getStatUpload()
        .then((r) => {
          this.$log.info('retrieved stat upload:', r);
          // Get earliest date:
          this.setUploads(r);
          this.status = allowedStates.IS_READY;
        }).catch((e) => {
          this.$log.error(e);
          this.toast.error('Failed to fetch upload usage statistics');
          this.status = allowedStates.IS_ERRORING;
          throw e;
        });
    },
    setUploads(uploadResponse) {
      const flatResponse = Object.entries(uploadResponse).map((entry) => ({ date: parseISO(entry[0]), count: entry[1].count }));
      if (Object.keys(flatResponse).length !== 2) {
        throw Error(`Upload response does not have the number of keys expected. ${uploadResponse}`);
      }
      this.$log.info('uploads flat response:', flatResponse);

      const firstDateIsEarliest = isBefore(flatResponse[0].date, flatResponse[1].date);
      const uploadsTotalIndex = firstDateIsEarliest ? 0 : 1;
      const uploadsLastMonth = uploadsTotalIndex === 0 ? 1 : 0;

      this.uploadsTotal = flatResponse[uploadsTotalIndex].count;
      this.uploadsLastMonth = flatResponse[uploadsLastMonth].count;
    },
  },
};
</script>
